import * as React from "react"
import tw from 'twin.macro'

const Loading = (props) => {
    const styles = {
        loadingBg:[
           `background-color: rgba(255,255,255,.8);
            position: fixed;
            z-index: 2;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%`,
            !props.visible && tw`hidden`
        ],
        loadingIco:[
           `border-radius: 50%;
            width: 80px;
            height: 80px;
            position: absolute;
            left: calc(50% - 40px);
            top: calc(50% - 40px);
            border-top: 8px solid rgba(35,97,249,.18);
            border-right: 8px solid rgba(35,97,249,.18);
            border-bottom: 8px solid rgba(35,97,249,.18);
            border-left: 8px solid #1921b1;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: loading .8s infinite linear;
            animation: loading .8s infinite linear`,
            `@-webkit-keyframes loading {
                0% {
                    -webkit-transform: rotate(0);
                    transform: rotate(0)
                }
            
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg)
                }
            }
            
            @keyframes loading {
                0% {
                    -webkit-transform: rotate(0);
                    transform: rotate(0)
                }
            
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg)
                }
            }`
        ]
    }

    return (
        <aside css={styles.loadingBg}>
            <div css={styles.loadingIco}></div>
        </aside>
    )
}

export default Loading
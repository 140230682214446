
import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import { Config } from './config'

export const instance = axios.create({
  baseURL: Config.baseUrl,
  withCredentials: true,
  headers: { 'Cache-Control': 'no-cache' },
  timeout: 100000,
  //  开启 throttle & cache 控制请求
  // defaultCache	CacheLike	new LRUCache({ maxAge: FIVE_MINUTES, max: 100 })

  //
  adapter: throttleAdapterEnhancer(
    // 这里是缓存时指 页面周期内，页面刷新重载入是相当于重新请求
    cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache',
    })
  ),
})


// request 拦截器
// eslint-disable-next-line
const requestInterceptors = instance.interceptors.request.use(
  (config) => {
    if(localStorage.getItem("naturalvoicer_token")){
      const token =  localStorage.getItem("naturalvoicer_token")
      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response 拦截器

// export function removeRequestInterceptors () {
//   instance.interceptors.request.eject(requestInterceptors)
// }
// export function removeResponseInterceptors () {
//   instance.interceptors.request.eject(responseInterceptors)
// }

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function get(url, params, options = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        ...options,
        params: params,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        msag(err)
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data, config) {
  return new Promise((resolve, reject) => {
    instance.post(url, data, config).then(
      (response) => {
        //关闭进度条
        resolve(response.data)
      },
      (err) => {
        console.log(err.response)
        msag(err)
        reject(err)
      }
    )
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data, config) {
  return new Promise((resolve, reject) => {
    instance.put(url, data, config).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        console.log(err.response)
        msag(err)
        reject(err)
      }
    )
  })
}
/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url, data, config) {
  return new Promise((resolve, reject) => {
    instance.delete(url, data, config).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        console.log(err.response)
        msag(err)
        reject(err)
      }
    )
  })
}


function msag(err) {
  if (err && err.response) {
    if(err.response.data.message){
      if(typeof(err.response.data.message) === 'string'){
        alert(err.response.data.message)
      }
      if(Array.isArray(err.response.data.message)){
        alert(err.response.data.message[0].messages[0].message)
      }
    }else{
      switch (err.response.status) {
        case 400:
          alert('Bad request!')
          break
        case 401:
          alert('Unauthorized: Please log in!')
          // 清除 token
          localStorage.removeItem("naturalvoicer_user")
          window.location.href = '/login'
          break
        case 403:
          alert('Access Denied!')
          break
  
        case 404:
          alert('Bad Address!')
          break
  
        case 408:
          alert('Request Timeout!')
          break
  
        case 423:
          // 用户被冻结
          alert('Your account has been disabled. To regain access please contact your admin.')
          // 清除 token
          localStorage.removeItem("naturalvoicer_user")
          setTimeout(() => {
            window.location.href = '/login'
          }, 2000)
          break
  
        case 500:
          alert('Internal Server Error!')
          break
  
        case 501:
          alert('Not yet implemented!')
          break
  
        case 502:
          alert('Bad Gateway!')
          break
  
        case 503:
          alert('Service Unavailable!')
          break
  
        case 504:
          alert('Gateway Timeout!')
          break
  
        case 505:
          alert('HTTP Version Not Supported!')
          break
        default:
      }
    }
  }
}
import React, { useState,useEffect } from 'react';
import Helmet from "react-helmet"

//提示插件
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

import { ReactComponent as Payment } from '../images/payment.svg'
import { ReactComponent as Contact } from '../images/contact.svg'
import { ReactComponent as Arrow } from '../images/arrow.svg'
import {post} from "../utils/base"
import { IS_PRO,Config } from '../utils/config'
import Layout from "../components/layout"
import Loading from "../components/loading"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default function PricePage({location}) {
    const [loadingVisible,setLoadingVisible] = useState(false);
    const payFun = (pid)=>{
        setLoadingVisible(true)
        if(window.Paddle){
            window.Paddle.Setup({ vendor: IS_PRO?116658:3365 });
            if(!IS_PRO){
                window.Paddle.Environment.set('sandbox');
            }
            window.Paddle.Checkout.open({
                product: pid,
                successCallback: (data)=>{
                    post(`${Config.baseUrl}/custom/change-role`,{
                        userID:data.user.id,
                        checkoutID:data.checkout.id
                    }).then(res => {
                        setLoadingVisible(false)
                        if(res.code === 1){
                            Toastify({
                                text: `You have paid successfully!`,
                                position:"center",
                                close: true
                            }).showToast();
                            window.location.href = `${Config.appDomain}/login?confirmed=1&email=${data.email}`
                        }
                    })
                },
                closeCallback: function(data){
                    setLoadingVisible(false)

                }
            })
        }
    }
    useEffect(()=>{
        if(window.Paddle){
            window.Paddle.Setup({ vendor: IS_PRO?116658:3365 });
            if(!IS_PRO){
                window.Paddle.Environment.set('sandbox');
            }
        }
    })
    
    return (
        <Layout>
            <GatsbySeo canonical={location.href} title="Pricing | Naturalvoicer" description="Pricing" />
            <Helmet>
                <script src="https://cdn.paddle.com/paddle/paddle.js" type="text/javascript"/>
            </Helmet>
            <Loading visible={loadingVisible}/>
            <div className="w-full container mx-auto pt-24 text-center">
                <h1 className="font-raleway font-extrabold text-4xl text-blue-darkest pb-5">Simple Plans for Daily Use</h1>
                <p className="pb-8">Upgrade to premium account and get unlimited characters. </p>
                {/* <ul className="flex justify-center items-center gap-5 pb-10">
                    <li className="bg-light font-bold text-blue rounded-3xl px-4 py-1">Personal</li>
                    <li className="font-bold">Business</li>
                </ul> */}
                <div className="grid grid-cols-3 gap-10 xl:grid-cols-1 pb-10">
                    <div className="text-center border-2 border-light rounded-lg hover:shadow-blueBox">
                        <div className="font-bold py-1 text-xl uppercase bg-light text-blue">Pro</div>
                        <div className="px-9 pb-5">
                            <span className="flex items-center justify-center w-20 h-20 mx-auto mt-4 mb-8 rounded-full bg-light text-gray"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="currentColor"><path d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,3,3v7H2V6A3,3,0,0,1,5,3ZM19,17H5a3,3,0,0,1-2.816-2H21.816A3,3,0,0,1,19,17Z"/></svg></span>
                            <div className="pb-5"><span className="text-[48px] font-extrabold text-blue-darkest">$9.99 </span> / Month</div>
                            <div onClick={()=>payFun(IS_PRO?672174:16770)} className="bg-blue text-white text-lg w-full rounded-lg flex items-center justify-center h-10 hover:bg-blue-dark hover:text-white hover:cursor-pointer">Buy Now</div>
                            <ul className="list-ok text-gray pt-5 mt-5 border-t border-gray-60 mb-10">
                                <li><span className="font-bold">0-100,000</span> characters a month</li>
                                <li>Unlimited use of different voices</li>
                                <li>Play & pause or change speed</li>
                                <li>Download as MP3 file</li>
                                <li>Support uploading documents</li>
                                <li>100M free cloud storage</li>
                                <li>Free for commercial use</li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-center border-2 border-yellow border-opacity-40 rounded-lg shadow-yellowBox">
                        <div className="font-bold py-1 text-xl uppercase bg-yellow text-yellow-darkest rounded-t-md bg-opacity-40">Plus</div>
                        <div className="px-9 pb-5">
                            <span className="flex items-center justify-center w-20 h-20 mx-auto mt-4 mb-8 rounded-full bg-light text-gray"><svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 24 24" width="36" fill="currentColor"><path d="m23.22 5.743-3.011-4.068a4.005 4.005 0 0 0 -3.254-1.675h-9.91a4 4 0 0 0 -3.245 1.656l-3.065 4.097a3.993 3.993 0 0 0 .123 4.784l8.792 12.328a2.985 2.985 0 0 0 2.35 1.135 3.006 3.006 0 0 0 2.378-1.175l8.722-12.191a4 4 0 0 0 .12-4.891zm-4.628-2.892 3.018 4.078c.016.022.02.048.036.071h-4.892l-1.428-5h1.629a2.012 2.012 0 0 1 1.637.851zm-6.592 16.267-2.7-10.118h5.4zm-2.674-12.118 1.428-5h2.492l1.428 5zm-3.909-4.163a2.007 2.007 0 0 1 1.628-.837h1.629l-1.428 5h-4.928c.014-.022.018-.049.034-.07zm-2.969 6.488a1.9 1.9 0 0 1 -.185-.325h4.969l2.994 11.23zm11.323 10.915 3-11.24h4.986a2.1 2.1 0 0 1 -.243.421z"/></svg></span>
                            <div className="pb-5"><span className="text-[48px] font-extrabold text-yellow-dark">$19.99 </span> / Month</div>
                            <div onClick={()=>payFun(IS_PRO?672175:16771)} className="bg-yellow text-white text-lg w-full rounded-lg flex items-center justify-center h-10 hover:bg-yellow-dark hover:text-white hover:cursor-pointer">Buy Now</div>
                            <ul className="list-ok yellow text-gray pt-5 mt-5 border-t border-gray-60 mb-10">
                                <li><span className="font-bold">0-500,000 </span> characters a month</li>
                                <li>Unlimited use of different voices</li>
                                <li>Play & pause or change speed</li>
                                <li>Download as MP3 file</li>
                                <li>Support uploading documents</li>
                                <li>100M free cloud storage</li>
                                <li>Free for commercial use</li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-center border-2 border-light rounded-lg hover:shadow-blueBox">
                        <div className="font-bold py-1 text-xl uppercase bg-light text-blue">Premium</div>
                        <div className="px-9 pb-5">
                            <span className="flex items-center justify-center w-20 h-20 mx-auto mt-4 mb-8 rounded-full bg-light text-gray"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36" fill="currentColor"><path d="M22.766,4.566A1.994,1.994,0,0,0,20.586,5L18,7.586,13.414,3a2,2,0,0,0-2.828,0L6,7.586,3.414,5A2,2,0,0,0,0,6.414V17a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V6.414A2,2,0,0,0,22.766,4.566ZM22,17a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6.414L5.293,9.707a1,1,0,0,0,1.414,0L12,4.414l5.293,5.293a1,1,0,0,0,1.414,0L22,6.414Z"/></svg></span>
                            <div className="pb-5"><span className="text-[48px] font-extrabold text-blue-darkest">$59.99 </span> / Month</div>
                            <div onClick={()=>payFun(IS_PRO?672177:16772)} className="bg-blue text-white text-lg w-full rounded-lg flex items-center justify-center h-10 hover:bg-blue-dark hover:text-white hover:cursor-pointer">Buy Now</div>
                            <ul className="list-ok text-gray pt-5 mt-5 border-t border-gray-60 mb-10">
                                    <li><span className="font-bold">0-1,000,000</span> characters a month</li>
                                    <li>Unlimited use of different voices</li>
                                    <li>Play & pause or change speed</li>
                                    <li>Download as MP3 file</li>
                                    <li>Support uploading documents</li>
                                    <li>500M free cloud storage</li>
                                    <li>Free for commercial use</li>
                                </ul>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center pb-10">
                    We accept: <Payment className="w-[337px] ml-5"/>
                </div>
                <div className="flex justify-center items-center bg-light rounded-lg py-4 px-[5%] lg:flex-col">
                    <Contact className="w-[80px]"/>
                    <p className="text-lg pl-8">Need more than 1 million characters? <a href="mailto:support@naturalvoicer.com" className="text-blue underline">Contact us</a> for a quote.</p>
                    <a href="support@naturalvoicer.com" className="ml-auto bg-blue text-white hover:bg-blue-darkest hover:text-white h-10 w-32 rounded flex justify-center items-center lg:ml-0 lg:mt-8">Get A Quote</a>
                </div>
                <div className="py-16">
                    <h2 className="font-extrabold font-raleway text-blue-darkest text-4xl mb-10">Sales FAQs</h2>
                    <details className="text-left mb-5">
                        <summary>
                            <h3 className="text-gray text-lg font-semibold py-5 px-6 bg-white shadow-faq flex items-center justify-between">Can I cancel the subscription? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <p className="bg-blue-darkest bg-opacity-5 py-2.5 px-6 text-base leading-7">By default, all subscriptions are set to auto-renew at the end of the billing period but you can turn this off at any time by selecting 'Cancel Subscription' from your account's Billing Information.</p>
                    </details>
                    <details className="text-left mb-5">
                        <summary>
                            <h3 className="text-gray text-lg font-semibold py-5 px-6 bg-white shadow-faq flex items-center justify-between">Can I get a refund if not satisfied? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <p className="bg-blue-darkest bg-opacity-5 py-2.5 px-6 text-base leading-7">If you are not satisfied with our products, you can request a refund within 14 days after purchase. To request a refund, please contact us at <a href="mailto:support@naturalvoicer.com" className="text-blue">support@naturalvoicer.com</a></p>
                    </details>
                    <details className="text-left mb-5">
                        <summary>
                            <h3 className="text-gray text-lg font-semibold py-5 px-6 bg-white shadow-faq flex items-center justify-between">Which plan is sutiable for me? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <div className="bg-blue-darkest bg-opacity-5 py-2.5 px-6 text-base leading-7">
                            <p className="pb-6">Our pricing plan is determined based on the number of characters that can be converted. Generally speaking, a 1000-word document has nearly 5000 characters. If it is in PDF or e-book format, the number of characters will be even more. You can choose the number of characters above according to your actual needs.</p>
                            <ul className="list-dot">
                                <li>Pro plan: 0-100,000 characters a month; $9.99/month.</li>
                                <li>Plus plan: 0-500,000 characters a month; $19.99/month.</li>
                                <li>Premium plan: 0-1,000,000 characters a month; $59.99/month.</li>
                            </ul>
                        </div>
                    </details>
                    <details className="text-left mb-5">
                        <summary>
                            <h3 className="text-gray text-lg font-semibold py-5 px-6 bg-white shadow-faq flex items-center justify-between">Can I upgrade to plus plan? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <p className="bg-blue-darkest bg-opacity-5 py-2.5 px-6 text-base leading-7">You can upgrade to a higher pricing plan at any time. Please note that the number of characters left over will not be added to the latest pricing plan.</p>
                    </details>
                </div>
            </div>
        </Layout>
    )
}

